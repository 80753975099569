<template>
  <div class="container-fluid" id="customer-container">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
            my-schedule
            mb-2
          "
        >
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="font-weight-bold">Wash Subscriptions</h4>
          </div>
          <div class="create-workform">
            <div
              class="
                d-flex
                flex-wrap
                align-items-center
                justify-content-between
              "
            >
              <div class="d-flex display-search align-items-center">
                <div class="mr-2 search-input">
                  <search
                    :search="search"
                    :placeholder="'Search'"
                    @searchFilter="handleSearch"
                    v-model="search"
                  >
                  </search>
                </div>
                <router-link
                  :to="{ path: 'washsubscription/add/' }"
                  class="btn btn-success btn-sm d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-1"
                    width="15"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                  <div class="white-space">Add Wash Subscription</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-block card-stretch">
              <div class="card-body-2">
                <div
                  class="
                    mb-2
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <h5>Wash Subscriptions List</h5>
                  <div class="d-flex flex-wrap mt-2 mobile-align-customer">
                    <div class="d-flex">
                      <div class="ml-2 filter-div">
                        <button
                          class="
                            btn btn-primary btn-sm
                            d-flex
                            align-items-center
                          "
                          @click="filterClick()"
                          v-on:click="showFilters = !showFilters"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="mr-1"
                            width="15"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          <div class="notify">
                            Filters
                            <div class="badge" v-if="countFilter">
                              <div class="message-count">{{ countFilter }}</div>
                            </div>
                          </div>
                        </button>
                      </div>

                      <div class="ml-2">
                        <button
                          class="
                            btn btn-warning btn-sm
                            d-flex
                            align-items-center
                            reset-btn-padding-onlist
                          "
                          @click="resetFilters"
                          title="Reset Filters"
                        >
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 1280.000000 1266.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,1266.000000) scale(0.100000,-0.100000)"
                              fill="white"
                              stroke="none"
                            >
                              <path
                                d="M6130 12653 c-1136 -47 -2253 -397 -3200 -1002 -1163 -744 -2048
                            -1830 -2525 -3096 -433 -1150 -520 -2406 -249 -3595 169 -740 463 -1430 880
                            -2063 765 -1161 1874 -2034 3189 -2509 977 -354 2044 -467 3080 -328 1388 186
                            2694 829 3675 1810 129 129 1201 1318 1207 1339 2 8 -1951 1746 -1964 1746 -5
                            0 -200 -214 -433 -475 -596 -668 -655 -732 -782 -847 -599 -539 -1284 -857
                            -2083 -964 -292 -39 -726 -36 -1025 7 -1069 153 -1996 719 -2605 1589 -746
                            1066 -863 2457 -305 3632 359 755 979 1375 1745 1744 387 186 728 287 1180
                            351 213 29 712 33 927 5 649 -82 1206 -288 1723 -638 269 -182 481 -372 781
                            -704 153 -168 162 -181 146 -196 -9 -9 -287 -254 -617 -544 -330 -291 -601
                            -532 -603 -537 -2 -4 4 -8 13 -8 8 0 305 -68 658 -150 353 -83 851 -200 1107
                            -260 256 -60 726 -170 1045 -245 319 -75 831 -195 1138 -267 307 -71 560 -128
                            562 -126 3 2 -47 960 -110 2129 -63 1168 -119 2221 -126 2339 -6 118 -14 245
                            -17 282 l-7 67 -520 -459 c-286 -252 -522 -459 -525 -459 -3 -1 -66 67 -140
                            150 -194 219 -425 458 -575 595 -1250 1147 -2934 1759 -4645 1687z"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <transition name="slide-fade">
                  <div class="row d-flex pb-5 pr-5 pl-5" v-if="showFilters">
                    <div class="col-md-3 filter-tabs">
                      <label
                        for="activated-at"
                        class="
                          form-label
                          text-uppercase text-muted
                          filter-label-heading
                          mt-3
                        "
                        >From Date (Activated At)</label
                      >
                      <b-input-group
                        :class="{
                          borderColorFilter: gte !== null ? true : false,
                        }"
                      >
                        <b-form-datepicker
                          id="datepicker-from-date"
                          v-model="gte"
                          size="sm"
                          calendar-width="100%"
                          placeholder="Select"
                          @context="onContext"
                          @input="onChangeFromDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        >
                        </b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeFromDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-3 filter-tabs">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted text-uppercase
                          filter-label-heading
                          mt-3
                        "
                        >To Date (Activated At)</label
                      >
                      <b-input-group
                        :class="{
                          borderColorFilter: lte !== null ? true : false,
                        }"
                      >
                        <b-form-datepicker
                          id="datepicker-to-date"
                          v-model="lte"
                          size="sm"
                          calendar-width="100%"
                          placeholder="Select"
                          @context="onContext"
                          @input="onChangeToDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        ></b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeToDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-3 filter-tabs">
                      <label
                        for="activated-at"
                        class="
                          form-label
                          text-uppercase text-muted
                          filter-label-heading
                          mt-3
                        "
                        >From Date (Updated At)</label
                      >
                      <b-input-group
                        :class="{
                          borderColorFilter: updated_gte !== null ? true : false,
                        }"
                      >
                        <b-form-datepicker
                          id="datepicker-update-from-date"
                          v-model="updated_gte"
                          size="sm"
                          calendar-width="100%"
                          placeholder="Select"
                          @context="onContext"
                          @input="onChangeUpdatedFromDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        >
                        </b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeUpdatedFromDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-3 filter-tabs">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted text-uppercase
                          filter-label-heading
                          mt-3
                        "
                        >To Date (Updated At)</label
                      >
                      <b-input-group
                        :class="{
                          borderColorFilter: updated_lte !== null ? true : false,
                        }"
                      >
                        <b-form-datepicker
                          id="datepicker-update-to-date"
                          v-model="updated_lte"
                          size="sm"
                          calendar-width="100%"
                          placeholder="Select"
                          @context="onContext"
                          @input="onChangeUpdatedToDate($event)"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                        ></b-form-datepicker>
                        <template v-slot:append>
                          <button
                            class="filter-cross-btn"
                            @click="removeUpdatedToDate()"
                          >
                            x
                          </button>
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-md-2 filter-tabs">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted
                          filter-label-heading
                          text-uppercase
                          mt-3
                        "
                        >Building</label
                      >

                      <multiselect
                        v-model="building"
                        :options="buildingArray"
                        :custom-label="getCustomBuilding"
                        placeholder="Select Building"
                        @select="onChangeBuilding"
                        @remove="removeBuilding"
                        :class="{
                          borderColorFilter: building !== null ? true : false,
                          singleOptions: true,
                        }"
                        :show-labels="false"
                        :allow-empty="true"
                      >
                      </multiselect>
                    </div>
                     <div class="col-md-2 customMultiSelect-div">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted
                          filter-label-heading
                          text-uppercase
                          mt-3
                        "
                        >Payment Method</label
                      >
                      <multiselect
                        v-model="paymentMethod"
                        :options="paymentMethodsArray"
                        :custom-label="customLabelPaymentMethod"
                        track-by="id"
                        placeholder="Select"
                        @search-change="asyncFindPaymentMethod"
                        @select="onChangePaymentMethod"
                        @remove="removePaymentMethod"
                        @open="handlePaymentMethod"
                        :show-labels="false"
                        :allow-empty="true"
                        :class="{
                          borderColorFilter: paymentMethod !== null ? true : false,
                          singleOptions: true,
                        }"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-2 filter-tabs">
                      <label
                        for="status"
                        class="
                          form-label
                          text-muted
                          filter-label-heading
                          text-uppercase
                          mt-3
                        "
                        >Status</label
                      >

                      <multiselect
                        v-model="status"
                        :options="statusArray"
                        placeholder="Select"
                        @select="onChangeStatus"
                        @remove="removeStatus"
                        :class="{
                          borderColorFilter: status !== null ? true : false,
                          singleOptions: true,
                        }"
                        :show-labels="false"
                        :allow-empty="true"
                      >
                      </multiselect>
                    </div>
                    <div class="col-md-2 filter-tabs">
                      <label
                        for="car-make"
                        class="
                          form-label
                          text-muted
                          filter-label-heading
                          text-uppercase
                          mt-3
                        "
                        >Week Day</label
                      >

                      <multiselect
                        v-model="weekDay"
                        :options="weekDayArray"
                        placeholder="Select"
                        @select="onChangeWeekDay"
                        @remove="removeWeekDay"
                        :class="{
                          borderColorFilter: weekDay !== null ? true : false,
                          singleOptions: true,
                        }"
                        :show-labels="false"
                        :allow-empty="true"
                      >
                      </multiselect>
                    </div>
                  </div>
                </transition>
                <div id="load-spinner">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>

                <div class="table-responsive table-zoom">
                  <table class="table data-table mb-0 table-customer">
                    <thead class="table-color-heading">
                      <tr class="">
                        <th scope="col">View</th>
                        <th scope="col">Number</th>
                        <th scope="col">Building</th>
                        <th scope="col">Package Type</th>
                        <th scope="col">Slots Day/Time</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Car</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="white-space-no-wrap transition-row"
                        v-for="washSubscription in washSubscriptions.edges"
                        :key="washSubscription.id"
                      >
                        <td>
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <router-link
                              :to="
                                'washsubscription/view/' +
                                washSubscription.node.id
                              "
                              class="click-area-extend"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="text-secondary"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                              </svg>
                            </router-link>
                          </div>
                        </td>
                        <td class="table-font">
                          <div title="Click To Copy">
                            <div
                              @click.prevent="
                                copyIdToClipboard(washSubscription.node.id)
                              "
                              class="washSubscription-id"
                            >
                              {{ washSubscription.node.id }}
                            </div>
                          </div>
                        </td>
                        <td class="table-font">
                          <div class="data-content">
                            {{ washSubscription.node.building.name }}
                          </div>
                        </td>
                        <td class="table-font">
                          <div class="data-content">
                            {{ washSubscription.node.package.type.name }}
                          </div>
                        </td>
                        <td class="table-font">
                          <div class="data-content-slots">
                            <p
                              v-for="(item, index) in washSubscription.node.scheduledSlots"
                              :key="index"
                            >
                              {{ item.weekDay }} - {{ item.fromTime | formatTime }} -
                              {{ item.toTime | formatTime }}
                            </p>
                          </div>
                        </td>
                        <td class="table-font">
                          <div class="data-content" v-if="washSubscription.node.customer">
                            {{
                              washSubscription.node.customer.firstName +
                              " " +
                              washSubscription.node.customer.lastName
                            }}
                          </div>
                          <div class="data-content" v-else>
                            -
                          </div>
                        </td>
                        <td class="table-font">
                          <div class="data-content">
                            {{
                              washSubscription.node.car.model.make.name +
                              " " +
                              washSubscription.node.car.model.name
                            }}
                          </div>
                        </td>
                        <td class="table-font">
                          <div class="data-content">
                            {{
                              washSubscription.node.total.currency +
                              " " +
                              washSubscription.node.total.amount
                            }}
                          </div>
                        </td>
                        <td class="table-font">
                          <div class="data-content">
                            {{ washSubscription.node.status }}
                          </div>
                        </td>
                      </tr>
                      <tr v-if="totalRows === 0">
                        <td colspan="8" align="center">No data found</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="p-3 row">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center">
                    <div>
                      Total Wash Subscriptions: <b>{{ totalRows }}</b>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                    <pagination
                      :currentPage="currentPage"
                      :perPage="perPage"
                      :totalRows="totalRows"
                      @changePage="handlePageChange"
                    ></pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import search from "../../../components/filters/search.vue";
import pagination from "../../../components/pagination/pagination.vue";
import {
  BWS_DASHBOARD_WASH_SUBSCRIPTIONS,
  GET_BUILDING_LIST,
  GET_PAYMENT_METHODS,
} from "../../../graphql/query";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { pagination, search },
  name: "WashSubscription",
  data() {
    return {
      checkedIsStaff: false,
      checkedIsActive: true,
      uppercased: [],
      weekDayArray: [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
      ],
      statusArray: [
        "ACTIVE",
        "CANCELLED",
        "NOT_ACTIVATED",
        "PAUSED",
        "COMPLETED"
      ],
      paymentMethods: [],
      paymentMethodsArray: [],
      searchPaymentMethod: "",
      buildingArray: [],
      building: null,
      paymentMethod: null,
      company: null,
      showFilters: false,
      washSubscriptions: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      after: null,
      before: null,
      hasNextPage: true,
      hasPreviousPage: false,
      last: null,
      first: null,
      search: null,
      searchText: null,
      status: null,
      updated_gte: null,
      updated_lte: null,
      gte: null,
      lte: null,
      resetSvg: require("../../../assets/images/svg/reset.svg"),
      countFilter: 0,
      washSubscriptionFilters: {
        user: null,
        status: null,
        weekDay: null,
        building: null,
        company: null,
        currency: null,
        filter_date_gte: null,
        filter_date_lte: null,
        updated_gte: null,
        updated_lte: null,
        paymentMethod: null,
      },
      washSubscriptionSearch: null,
    };
  },
  computed: {
    ...mapGetters({
      stateWashSubscriptionFilters: "washSubscriptionFilters",
      stateSearch: "washSubscriptionSearch",
    }),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
      if (
        from.path !== "washsubscription/view" &&
        from.path !== "washsubscription/add" &&
        from.path !== null
      ) {
        vm.washSubscriptionSearch = null;
        vm.changeSearch(vm.washSubscriptionSearch);
      }
    });
  },
  mounted() {
     if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      localStorage.removeItem('customer-phone');
      localStorage.removeItem('customer-car');
      location.reload();
    }
    if (localStorage.getItem("vuex") !== null) {
      this.first = this.perPage;

      // this.getStateLocalStorage();

      this.washSubscriptionFilters = this.stateWashSubscriptionFilters;
      this.changeWashSubscriptionFilters(this.stateWashSubscriptionFilters);

      this.washSubscriptionSearch = this.stateSearch;
      this.changeSearch(this.stateSearch);
      this.getWashSubscription();
      this.getBuildings();
    }
  },
  watch: {
    washSubscriptionFilters: function () {
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
    },
    washSubscriptionSearch: function () {
      this.changeSearch(this.washSubscriptionSearch);
    },
  },
  methods: {
    ...mapActions({
      washSubscriptionFilterChange: "washSubscriptionFilterAction",
      searchChange: "washSearchAction",
    }),
    filterClick() {
      this.countFilter = document.querySelectorAll(".borderColorFilter").length;
    },
    handlePaymentMethod() {
      // this.$forceUpdate();
      this.$apollo
        .query({
          query: GET_PAYMENT_METHODS,
          variables: {
            first: 10,
            search: this.searchPaymentMethod,
          },
        })
        .then((data) => {
          this.paymentMethods = data.data.bwsDashboardPaymentMethods.edges;
          this.paymentMethodsArray = this.paymentMethods.map((res) => {
            return res.node;
          });
        });
    },
    asyncFindPaymentMethod(query) {
      this.searchPaymentMethod = query;
      this.handlePaymentMethod();
    },
    async getBuildings() {
      await this.$apollo
        .query({
          query: GET_BUILDING_LIST,
          variables: {
            first: 10,
          },
        })
        .then((data) => {
          this.buildingArray = data.data.bwsDashboardBuildings;
        });
    },
    //Buildings
    getCustomBuilding(opt) {
      return opt.name;
    },
    // Companies
    getCustomCompany(opt) {
      return opt.legalName;
    },
    changeWashSubscriptionFilters(value) {
      this.countFilter = 0;
      this.washSubscriptionFilterChange({ washSubscriptionFilters: value });
      if (
        value.company !== null ||
        value.paymentMethod !== null ||
        value.updated_gte !== null ||
        value.updated_lte !== null ||
        value.building !== null ||
        value.filter_date_lte !== null ||
        value.filter_date_gte !== null ||
        value.weekDay !== null ||
        value.status !== null ||
        value.washSubscriptionSearch !== null
      ) {
        if (value.company) {
          this.showFilters = true;
          this.company = this.washSubscriptionFilters.company;
          this.countFilter += 1;
        } else {
          this.company = null;
          this.showFilters = false;
        }

        if (value.building) {
          this.showFilters = true;
          this.building = this.washSubscriptionFilters.building;
          this.countFilter += 1;
        } else {
          this.building = null;
          this.showFilters = false;
        }

        if (value.status) {
          this.showFilters = true;
          this.status = this.washSubscriptionFilters.status;
          this.countFilter += 1;
        } else {
          this.status = null;
          this.showFilters = false;
        }

        if (value.paymentMethod) {
          this.showFilters = true;
          this.paymentMethod = this.washSubscriptionFilters.paymentMethod;
          this.countFilter += 1;
        } else {
          this.paymentMethod = null;
          this.showFilters = false;
        }

        if (this.washSubscriptionFilters.updated_gte) {
          this.updated_gte = this.washSubscriptionFilters.updated_gte;
          this.countFilter += 1;
        }

        if (this.washSubscriptionFilters.updated_lte) {
          this.updated_lte = this.washSubscriptionFilters.updated_lte;
          this.countFilter += 1;
        }

        if (this.washSubscriptionFilters.filter_date_lte) {
          this.lte = this.washSubscriptionFilters.filter_date_lte;
          this.countFilter += 1;
        }

        if (this.washSubscriptionFilters.filter_date_gte) {
          this.gte = this.washSubscriptionFilters.filter_date_gte;
          this.countFilter += 1;
        }

        if (this.washSubscriptionFilters.weekDay) {
          this.showFilters = true;
          this.weekDay = this.washSubscriptionFilters.weekDay;
          this.countFilter += 1;
        } else {
          this.weekDay = null;
          this.showFilters = false;
        }
      } else {
        this.showFilters = false;
      }
    },

    changeSearch(value) {
      // this.countFilter = 0;
      this.searchChange({ washSubscriptionSearch: value });
      if (this.washSubscriptionSearch) {
        this.search = this.washSubscriptionSearch;
      }
    },

    customLabelPaymentMethod(option) {
      if (option.name) {
        return `${option.name} - ${option.currency}`;
      }
    },
    getWashSubscription() {
      document.getElementById('load-spinner').style.display = 'flex';
      this.$apollo
        .query({
          query: BWS_DASHBOARD_WASH_SUBSCRIPTIONS,
          variables: {
            first: this.first,
            last: this.last,
            after: this.after,
            before: this.before,
            user: this.user,
            status: this.status,
            weekDay: this.weekDay,
            building: this.building ? this.building.id : null,
            company: this.company ? this.company.id : null,
            search: this.search,
            gte: this.gte,
            lte: this.lte,
            updated_gte: this.updated_gte,
            updated_lte: this.updated_lte,
            paymentMethod: this.paymentMethod ? this.paymentMethod.id : null,
            currency: this.currency,
          },
        })
        .then((data) => {
          document.getElementById('load-spinner').style.display = 'none';
          this.washSubscriptions = data.data.bwsDashboardSubscriptions;
          this.totalRows = data.data.bwsDashboardSubscriptions.totalCount;
          this.after = data.data.bwsDashboardSubscriptions.pageInfo.endCursor;
          this.before = data.data.bwsDashboardSubscriptions.pageInfo.startCursor;
          this.hasNextPage =
            data.data.bwsDashboardSubscriptions.pageInfo.hasNextPage;
          this.hasPreviousPage =
            data.data.bwsDashboardSubscriptions.pageInfo.hasPreviousPage;
        });
    },
    handlePageChange(event) {
      if (event > this.currentPage && this.hasNextPage) {
        this.currentPage = event;
        this.before = null;
        this.last = null;
        this.first = this.perPage;
        this.after;
        this.getWashSubscription();
      } else if (event < this.currentPage && this.hasPreviousPage) {
        this.currentPage = event;
        this.after = null;
        this.before;
        this.last = this.perPage;
        this.first = null;
        this.getWashSubscription();
      }
    },
    handleSearch(event) {
      this.search = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.washSubscriptionSearch = event;
      this.changeSearch(this.washSubscriptionSearch);
      this.getWashSubscription();
    },
    onContext(ctx) {
      this.nextServiceDue = ctx.selectedYMD;
    },
    onChangeUpdatedFromDate(event) {
      this.updated_gte = event;
      this.washSubscriptionFilters.updated_gte = event;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },

    onChangeUpdatedToDate(event) {
      this.updated_lte = event;
      this.washSubscriptionFilters.updated_lte = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removeUpdatedFromDate() {
      this.updated_gte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.washSubscriptionFilters.updated_gte = null;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removeUpdatedToDate() {
      this.updated_lte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.washSubscriptionFilters.updated_lte = null;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    onChangeFromDate(event) {
      this.gte = event;
      this.washSubscriptionFilters.filter_date_gte = event;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },

    onChangeToDate(event) {
      this.lte = event;
      this.washSubscriptionFilters.filter_date_lte = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removeFromDate() {
      this.gte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.washSubscriptionFilters.filter_date_gte = null;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removeToDate() {
      this.lte = null;
      this.after = null;
      this.before = null;
      this.currentPage = 1;
      this.last = null;
      this.first = this.perPage;
      this.washSubscriptionFilters.filter_date_lte = null;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },

    onChangePaymentMethod(event) {
      this.paymentMethod = event.id;
      this.washSubscriptionFilters.paymentMethod = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removePaymentMethod(selectedOption) {
      if (selectedOption) {
        this.paymentMethod = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.washSubscriptionFilters.paymentMethod = null;
        this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
        this.getWashSubscription();
      }
    },

    onChangeBuilding(event) {
      this.building = event.id;
      this.washSubscriptionFilters.building = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removeBuilding(selectedOption) {
      if (selectedOption) {
        this.building = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.washSubscriptionFilters.building = null;
        this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
        this.getWashSubscription();
      }
    },
    onChangeCompany(event) {
      this.company = event.id;
      this.washSubscriptionFilters.company = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removeCompany(selectedOption) {
      if (selectedOption) {
        this.company = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.washSubscriptionFilters.company = null;
        this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
        this.getWashSubscription();
      }
    },

    onChangeStatus(event) {
      this.status = event;
      this.washSubscriptionFilters.status = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removeStatus(selectedOption) {
      if (selectedOption) {
        this.status = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.washSubscriptionFilters.status = null;
        this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
        this.getWashSubscription();
      }
    },

    onChangeWeekDay(event) {
      this.weekDay = event;
      this.washSubscriptionFilters.weekDay = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
    },
    removeWeekDay(selectedOption) {
      if (selectedOption) {
        this.weekDay = null;
        this.before = null;
        this.after = null;
        this.last = null;
        this.first = this.perPage;
        this.currentPage = 1;
        this.washSubscriptionFilters.weekDay = null;
        this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
        this.getWashSubscription();
      }
    },
    resetFilters() {
      this.currentPage = 1;
      this.after = null;
      this.before = null;
      this.first = this.perPage;
      (this.last = null), (this.status = null), (this.building = null);
      this.company = null;
      this.user = null;
      this.weekDay = null;
      this.gte = null;
      this.lte = null;
      this.updated_gte = null;
      this.updated_lte = null;
      this.paymentMethod = null;

      this.washSubscriptionFilters.status = null;
      this.washSubscriptionFilters.weekDay = null;
      this.washSubscriptionFilters.filter_date_gte = null;
      this.washSubscriptionFilters.filter_date_lte = null;
      this.washSubscriptionFilters.building = null;
      this.washSubscriptionFilters.company = null;
      this.washSubscriptionFilters.updated_gte = null;
      this.washSubscriptionFilters.updated_lte = null;
      this.washSubscriptionFilters.paymentMethod = null;

      // localStorage.removeItem("vuex");
      this.changeWashSubscriptionFilters(this.washSubscriptionFilters);
      this.getWashSubscription();
      location.reload();
    },
    copyIdToClipboard(id) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = id;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");
      var message = "Copied";
      this.$bvToast.toast(id + " " + `${message}`, {
        toaster: "b-toaster-top-center",
        variant: "success",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },
  },
};
</script>

<style>
.filter-btn {
  font-size: 1.1rem !important;
}
table.table-customer {
  text-align: center;
}
.singleOptions .multiselect__tags {
  min-height: 47px;
}
.transition-row {
  transition: transform 0.1s;
}
.transition-row:hover {
  background-color: rgb(245, 245, 245);
  /* transform: scale(0.99); */
}
.click-area-extend {
  padding: 0.25rem 1rem 0.25rem 1rem;
  cursor: pointer;
}
.toast-style {
  font-size: 1.2rem;
  padding: 1rem;
}
.washSubscription-id:hover {
  color: blue;
  cursor: pointer;
}
.text-area-clipboard {
  border: none;
  color: white;
  height: 0;
  width: 0;
}
.table-zoom {
  zoom: 90%;
}
.white-space {
  overflow: auto;
  white-space: nowrap;
}

.card-body-2 {
  padding: 10px 20px 20px 20px;
}
.sorting-div .singleOptions .multiselect__tags {
  min-height: 10px !important;
}

.singleOptions .multiselect__tags {
  min-height: 37px !important;
}

.multiselect {
  min-height: 37px;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}

.multiselect__input,
.multiselect__single {
  min-height: 12px !important;
  line-height: 14px !important;
}

.multiselect__select {
  height: 31px !important;
}

.filter-cross-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 0.75rem 0 0.75em;
  border-radius: 0.25rem;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  overflow: auto !important;
  white-space: nowrap !important;
}
.reset-btn-padding {
  padding: 0.2rem;
}

.reset-btn-padding-onlist {
  padding: 0.4rem;
}

.notify .badge {
  background: radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -moz-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -ms-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -o-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -webkit-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background-color: #ed2324;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 5% 5%;
  float: right;
  display: block;
  position: relative;
  top: -16px;
  left: 12px;
}
.filter-label-heading {
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: capitalize!important;
}

.message-count {
  position: relative;
  display: block;
  font: bold 14px/13px Helvetica, Verdana, Tahoma;
  text-align: center;
  margin: 0;
  top: 2px;
}

#customer-container .borderColorFilter {
  /* background-color: gray; */
  border: 2px solid #9a18ffd1 !important;
  border-radius: 5px;
}

#customer-container .filter-tabs.col-md-3,
#customer-container .filter-tabs.col-md-2 {
  flex: 0 0 20%;
  max-width: 20%;
}

#customer-container .borderColorFilter.is-true {
  border: none !important;
}

#customer-container
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #3378ff;
  border-color: #3378ff;
  border: 2px solid #9a18ffd1 !important;
  border-radius: 10px;
}

#load-spinner{
    justify-content: center;
    display: none;
}

.display-search .search-input{
  margin-top: 45px;
}

@media only screen and (max-width: 768px) {
  .table th,
  .table td {
    padding: 10px 5px;
  }

  #customer-container .filter-tabs.col-md-3,
  #customer-container .filter-tabs.col-md-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 470px) {
}

@media only screen and (max-width: 417px) {
  .sorting-div {
    margin-bottom: 1rem;
  }

  .display-search.d-flex{
    display: block !important;
  }
  .display-search .btn{
    width: 65%;
  }
  /* .mobile-align-customer {
    justify-content: flex-start;
  } */
  .filter-div {
    margin-left: 0rem !important;
  }
}
</style>
